import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, List, Link, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";

import { useLoginState } from './userStateContext';

import localFavicon from '../resources/Icons/logo_32x32.ico';
import SOMALogo from '../resources/Logos/Logo.jpeg';

export default (() => {

	const { loginState, updateLoginState } = useLoginState(false);

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"instructions-for-reviewers"} />
		<Helmet>
			<title>
				Instructons - For reviewers
			</title>
			<meta name={"description"} content={"Web site created for NJSOMA"} />
			<link rel={"shortcut icon"} href={localFavicon} type={"image/x-icon"} />
		</Helmet>
		<Section>
			<Override slot="SectionContent" />
			<Box
				min-width="100px"
				min-height="100px"
				order="0"
				align-self="stretch"
				display="flex"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						<Image
							src={SOMALogo}
							display="block"
							max-height="150px"
							max-width="150px"
							srcSet=""
							sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
					</Link>
				</Box>
				<Box min-width="100px" min-height="100px" align-self="center">
					<Text margin="0px 0 24px 0" text-align="center" font="--headline2" md-font="--headline3">
						National Journal of Society of Medical Anatomists
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Input
						display="block"
						placeholder-color="LightGray"
						background="white"
						id="loginUser"
						className="LoginUser"
						defaultValue="User ID :"
						disabled
						opacity={0}/*{loginState ? 1 : 0}*/
					/>
					<Button opacity={0}/*{loginState ? 0 : 1}*/ id="loginRegBtn" className="LoginRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Login
					</Button>
					<Button opacity={0}/*{loginState ? 1 : 0}*/ id="logoutRegBtn" className="LogoutRegBtn" onClick={() => {
							//window.location.href='/loginPages/landingPage';	
						}}
					>
						Logout
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="16px 0 16px 0" quarkly-title="Header" align-items="center" justify-content="center">
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
			/>
			<Box min-width="100px" min-height="100px" />
			<Box>
				<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
					<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
					<Override
						slot="menu"
						md-top={0}
						md-width="100%"
						justify-content="center"
						lg-transition="transform 400ms ease 0s"
						md-position="fixed"
						display="flex"
						md-left={0}
						lg-transform="translateY(0px) translateX(0px)"
						md-height="100%"
						padding="0px 0 0px 0"
						align-items="center"
					>
						<Override
							slot="item"
							md-padding="16px 40px 16px 40px"
							display="inline-block"
							text-transform="uppercase"
							text-align="center"
							padding="8px 20px 8px 20px"
						/>
						<Override slot="item-404" lg-display="none" display="none" />
						<Override slot="item-index" lg-display="none" display="none" />
						<Override
							slot="link"
							md-hover-opacity="1"
							md-active-opacity="1"
							md-color="--dark"
							font="--base"
							text-transform="initial"
							md-font="16px/24px sans-serif"
							text-decoration-line="initial"
							color="--dark"
							transition="opacity .15s ease 0s"
							letter-spacing="0.5px"
							md-opacity=".5"
							md-transition="opacity .15s ease 0s"
							opacity=".5"
							hover-opacity="1"
						/>
						<Override
							slot="link-active"
							md-opacity="1"
							md-cursor="default"
							opacity="1"
							color="--primary"
							cursor="default"
						/>
					</Override>
					<Override slot="icon,icon-close" category="md" icon={MdMenu} />
					<Override
						slot="icon"
						category="md"
						icon={MdMenu}
						size="36px"
						md-right="0px"
						md-position="relative"
					/>
					<Override
						slot="menu-open"
						md-justify-content="center"
						md-top={0}
						md-bottom={0}
						md-display="flex"
						md-flex-direction="column"
						md-align-items="center"
					/>
				</Components.BurgerMenu>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-5">
			<Override slot="SectionContent" padding="0px 0 0px 0px" lg-padding="0px 0 0px 0px" />
			<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans" max-width="640px">
				Guidelines For Reviewers :
			</Text>
			<Text margin="0px 0px 80px 0px" font="normal 300 25px/1.5 --fontFamily-sans" color="#505257" max-width="90%">
				<List
					margin="0px 0px 0px 0px"
					padding="0px 0px 0px 20px"
					list-style-type="disc"
					as="ul"
					xl-font="300 25px/1.5 --fontFamily-sans"
				>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						The unpublished manuscript is a privileged document. Please protect it 
	from any form of exploitation. Reviewers are expected not to cite a 
	manuscript or refer to the work it describes before it has been 
	published, and to refrain from using the information it contains for the
	advancement of their own research.
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						A reviewer should consciously adopt a positive, impartial attitude 
	towards the manuscript under review. Your position should be that of the
	author's ally, with the aim of promoting effective and accurate 
	scientific communication.
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						If you believe that you cannot judge a given article impartially, please
	return the manuscript immediately to the editor with that explanation.
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						Reviews should be completed expeditiously, within 3-4 weeks. If you know
	that you cannot finish the review within the time specified, please 
	inform the editor.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						A reviewer should not discuss a paper with its author/s. If you want to 
	consult a colleague or junior please discuss this with us first.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						Please do not make any specific statement about the acceptability of a 
	paper in your comments for transmission to the author, but advise the 
	editor on sheet provided.
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						In your review, please consider the following aspects of the manuscript as far as they are applicable:<br/>
						<List
							margin="0px 0px 0px 0px"
							padding="0px 0px 0px 20px"
							list-style-type="decimal"
							as="ul"
							xl-font="300 25px/1.5 --fontFamily-sans"
						>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								Scientific reliability{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								Importance (clinical or otherwise) of the question or subject studied{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								Originality (truly original or known to you through foreign or specialist publications or through the grapevine){" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								Adequacy of abstract, keywords.{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								Appropriateness of approach or experimental design, adequacy of experimental techniques (including statistics where appropriate, need for statistical assessment). Methods adequately described? Appropriate? Patients studied adequately described and their condition defined?{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								Are results relevant to the problem posed? Credible? Well presented?
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								The soundness of conclusions and interpretation. Interpretation and conclusions warranted by the data? Reasonable speculation? Is the message clear?{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								Relevance of discussion{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								References up to date and relevant? Any glaring omissions?{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								The relevance of the figures and table, clarity of legends and titles.{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								Suitability for the CSDM and overall recommendations. Appropriate for general readership or more appropriate for a specialist journal?{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								If not acceptable can the paper be made so?{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								Ethical aspects{" "}
							</Text>
							<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
								Overall presentation (including writing style, clarity of writing)
							</Text>
						</List>
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						In comments intended for the author's, criticism should be presented dispassionately, and abrasive remarks avoided.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						Suggested revisions should be couched as such, and not expressed as conditions of acceptance. Please distinguish between revisions considered essential and those judged merely desirable.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						Even if we do not accept a paper we would like to pass on constructive comments that might help the author to improve it. For this reason please give detailed comments (with references, if appropriate) that will help both the editors to make a decision on the paper and the authors to improve it.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						Your criticism, arguments, and suggestions concerning that paper will be most useful to the editor if they are carefully documented.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						You are not requested to correct mistakes/s in grammar, but any help in this regard will be appreciated.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						The editor gratefully receives a reviewer's recommendations, but since the editorial decisions are usually based on evaluations derived from several sources, a reviewer should not expect the editor to honour his or her every recommendation.{" "}
					</Text>
					<Text margin="0px 0px 0px 0px" xl-font="300 25px/1.5 --fontFamily-sans">
						Please check for plagiarism using the link provided{" "}
						<Link href="https://www.plagramme.com ">
							https://www.plagramme.com{" "}
						</Link>
					</Text>
				</List>
			</Text>
			<Text margin="0px 0px 80px 0px" font="normal 300 25px/1.5 --fontFamily-sans" color="#505257" max-width="90%">
				(These guidelines are based on the guidelines provided by Council of Science Editors)
			</Text>
		</Section>
		<Section
			box-sizing="border-box"
			quarkly-title="Footer"
			justify-content="center"
			margin="0 0 0 0"
			padding="16px 0 16px 0"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				width="100%"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-gap="16px"
				display="grid"
				grid-gap="32px"
			>
				<Box display="flex" align-items="center" sm-flex-wrap="wrap">
					<Image
						width="28px"
						height="28px"
						src={SOMALogo}
						srcSet=""
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Link
						opacity="0.6"
						text-decoration-line="initial"
						text-align="left"
						hover-text-decoration-line="underline"
						font="--base"
						color="--dark"
						white-space="nowrap"
						href="https://www.societyofmedicalanatomists.com/"
						margin="1px 0px 0px 10px"
					>
						SOMA
					</Link>
				</Box>
				<SocialMedia facebook="https://www.facebook.com/NJSOMA/" twitter="https://twitter.com/njsoma" youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw" justify-content="flex-end">
					<Override slot="link" background="none" border-radius="50%" />
					<Override slot="icon" color="--dark" />
				</SocialMedia>
			</Box>
		</Section>
	</Theme>;
});